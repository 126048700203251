import React from "react";

import "./HeaderRow.css";

export const HeaderRow = () => {
  return (
    <div className="Header-row">
      <span className="Die-bonus-header">+to Die</span>
      <span className="Total-bonus-header">+to Total</span>
    </div>
  );
};
