import React from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import { RollResult } from "../../compute/Roll";

import "./ResultsPanel.css";

export type ResultsPanelProps = {
  clearResult: (index: number) => void;
  clearResults: () => void;
  results: Array<RollResult>;
};

export const constructTimestamp = (index: number, timestamp: Date): string =>
  `[#${index} @ ${timestamp.getUTCHours()}:${timestamp.getUTCMinutes()}:${timestamp.getUTCMilliseconds()}]`;

export const constructDiceIndicator = (count: number, faces: number): string =>
  `${count}d${faces}: `;

export const constructDieRolls = (
  result: Array<number>,
  addToDie: number
): Array<string> =>
  result.map(
    (roll, index) =>
      `${index === 0 ? "" : ", "}${roll}${addToDie > 0 ? `+${addToDie}` : ""}`
  );

export const constructSubTotal = (subTotal: number, addToTotal: number) =>
  addToTotal ? ` = ${subTotal} + ${addToTotal}` : "";

export const constructTotal = (total: number): string => ` = ${total}`;

export const ResultsPanel = ({
  clearResult,
  clearResults,
  results,
}: ResultsPanelProps) => (
  <div className="Results-panel">
    <div className="Results-clear-button-container">
      <div className="Results-clear-button-offset" />
      <Button disabled={results.length === 0} onClick={() => clearResults()}>
        Clear
      </Button>
    </div>
    {results.map(
      (
        {
          addToDie,
          addToTotal,
          count,
          faces,
          result,
          subTotal,
          timestamp,
          total,
        },
        index
      ) => (
        <div className="Results-row" key={uuidv4()}>
          <span className="Results-timestamp">
            {constructTimestamp(results.length - index, timestamp)}
          </span>
          <span className="Results-data">
            {constructDiceIndicator(count, faces)}
            {constructDieRolls(result, addToDie)}
            {constructSubTotal(subTotal, addToTotal)}
            {constructTotal(total)}
          </span>
          <Button
            data-testid="clear-row-button"
            onClick={() => clearResult(index)}
          >
            <Icon>close</Icon>
          </Button>
        </div>
      )
    )}
  </div>
);
