export type RollResult = {
  addToDie: number;
  addToTotal: number;
  count: number;
  faces: number;
  result: Array<number>;
  subTotal: number;
  timestamp: Date;
  total: number;
};

export const rollDie = (faces: number): number =>
  Math.floor(Math.random() * faces) + 1;

export const roll = (
  count: number,
  faces: number,
  addToDie: number,
  addToTotal: number
): RollResult => {
  let total = addToTotal;
  const result = [];
  for (let i = 0; i < count; i++) {
    const roll = rollDie(faces);
    result.push(roll);
    total = total + roll + addToDie;
  }

  return {
    addToDie,
    addToTotal,
    count,
    faces,
    result,
    subTotal: total - addToTotal,
    timestamp: new Date(),
    total,
  };
};

export const rollWithoutLowest = (
  count: number,
  faces: number,
  addToDie: number,
  addToTotal: number
): RollResult => {
  let total = addToTotal;
  const result = [];
  for (let i = 0; i < count; i++) {
    const roll = rollDie(faces);
    result.push(roll);
    total = total + roll + addToDie;
  }

  total = total - Array.from(new Set(result)).sort()[0];

  return {
    addToDie,
    addToTotal,
    count,
    faces,
    result,
    subTotal: total - addToTotal,
    timestamp: new Date(),
    total,
  };
};
