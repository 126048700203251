import React, { useState } from "react";
import ReactGA from "react-ga4";

import { HeaderRow } from "../HeaderRow";
import { DieRow } from "../DieRow";
import { CustomRow } from "../CustomRow";
import { ResultsPanel } from "../ResultsPanel";
import { roll, rollWithoutLowest, RollResult } from "../../compute/Roll";

import "./App.css";

export const App = () => {
  const [results, setResults] = useState<Array<RollResult>>([]);

  const sendMetricEvent = (category: string, action: string, total?: number) => {
    ReactGA.event({
      category,
      action,
      label: action,
      value: total,
      nonInteraction: false,
      transport: 'xhr'
    });
  }

  const sendRollMetricEvent = ({ addToDie, addToTotal, count, faces, total }: RollResult) => {
    sendMetricEvent('dice-roll', `Roll ${count}d${faces}`, total);

    if (addToDie) {
      sendMetricEvent('dice-roll', `Adding ${addToDie} to each die of ${count}d${faces} roll`, addToDie);
    }

    if (addToTotal) {
      sendMetricEvent('dice-roll', `Adding ${addToTotal} to total of ${count}d${faces}`, addToTotal);
    }
  }

  const clearResult = (index: number): void => {
    setResults([...results.slice(0, index), ...results.slice(index + 1)]);
    sendMetricEvent('clear-result', 'Clearing Single Result');
  };

  const clearResults = (): void => {
    setResults([]);
    sendMetricEvent('clear-results', 'Clearing All Results');
  }

  const addRoll = (
    count: number,
    faces: number,
    addToDie: number,
    addToTotal: number
  ) => {
    const rollResult: RollResult = roll(count, faces, addToDie, addToTotal);
    setResults([rollResult, ...results]);

    sendRollMetricEvent(rollResult);
  };

  const removeLowestDie = (
    count: number,
    faces: number,
    addToDie: number,
    addToTotal: number
  ) => {
    const rollResult: RollResult = rollWithoutLowest(count, faces, addToDie, addToTotal);
    setResults([rollResult, ...results]);

    sendMetricEvent('dice-roll', `Roll ${count}d${faces} - subtract lowest`, rollResult.total);
  };

  return (
    <div className="App">
      <header className="App-header">Dice Roller</header>
      <div className="App-body">
        <div className="Dice-panel">
          <HeaderRow />
          <DieRow addRoll={addRoll} faces={3} />
          <DieRow addRoll={addRoll} faces={4} />
          <DieRow addRoll={addRoll} faces={6} />
          <DieRow addRoll={addRoll} faces={8} />
          <DieRow addRoll={addRoll} faces={10} />
          <DieRow addRoll={addRoll} faces={12} />
          <DieRow addRoll={addRoll} faces={20} />
          <DieRow addRoll={addRoll} faces={100} />
          <span></span>
          <CustomRow addRoll={addRoll} />
          <span>Roll and Remove Lowest Die</span>
          <DieRow addRoll={removeLowestDie} defaultCount={4} faces={6} />
        </div>
        <ResultsPanel
          clearResult={clearResult}
          clearResults={clearResults}
          results={results}
        />
      </div>
    </div>
  );
};
