import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "./CustomRow.css";

export type CustomRowProps = {
  addRoll: (
    count: number,
    faces: number,
    addToDie: number,
    addToTotal: number
  ) => void;
  defaultCount?: number;
};

export const CustomRow = ({ addRoll, defaultCount = 1 }: CustomRowProps) => {
  const [count, setCount] = useState<number>(1);
  const [faces, setFaces] = useState<number>(1);
  const [addToDie, setAddToDie] = useState<number>(0);
  const [addToTotal, setAddToTotal] = useState<number>(0);

  useEffect(() => setCount(defaultCount), [defaultCount]);

  const handleValueChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setCount(parseInt(value));

  const handleFacesValueChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setFaces(parseInt(value));

  const handleAddToDieValueChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setAddToDie(parseInt(value));

  const handleAddToTotalValueChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setAddToTotal(parseInt(value));

  const handleRollClick = () => addRoll(count, faces, addToDie, addToTotal);

  return (
    <div className="Custom-row">
      <TextField
        className="Count-text-field Row-text-field"
        data-testid="Count-text-field"
        onChange={handleValueChange}
        type="number"
        value={count}
      />
      <span className="D-span">d</span>
      <TextField
        className="Faces-text-field Row-text-field"
        data-testid="Faces-text-field"
        onChange={handleFacesValueChange}
        type="number"
        value={faces}
      />
      <TextField
        className="Additional-bonus-text-field Row-text-field"
        data-testid="Additional-bonus-die-text-field"
        onChange={handleAddToDieValueChange}
        type="number"
        value={addToDie}
      />
      <TextField
        className="Additional-bonus-text-field Row-text-field"
        data-testid="Additional-bonus-total-text-field"
        onChange={handleAddToTotalValueChange}
        type="number"
        value={addToTotal}
      />
      <Button
        className="Roll-button"
        data-testid="Roll-button"
        onClick={handleRollClick}
        variant="contained"
      >
        Roll
      </Button>
    </div>
  );
};
